import React from "react";
import PropTypes from "prop-types";
import PriceButton from "../PriceButton";
import AbstractPriceWidget from "../AbstractPriceWidget";
import GoogleAnalytics from "../../../helper/GoogleAnalytics";

export class PriceWidgetAlarmPanicButton extends AbstractPriceWidget {
    constructor(props) {
        super(props);
        let { panic_button } = this.prices;
        this.progress = 80;
        this.cssClass = "panic_button";
        if (this.isObjectTypeBusiness()) {
            this.question =
                "Möchten Sie gerne einen Überfallalarmschalter installieren lassen?";
        } else {
            this.question =
                "Möchten Sie gerne einen Überfallalarmschalter installieren lassen (z.B. im Schlafzimmer)?";
        }
        this.setEventName("PanicButton");
        if (panic_button !== null) {
            this.updated([
                { key: "nextButtonStep", value: "alarmSmartphone" },
                { key: "showNextButton", value: true },
            ]);
        }
    }
    componentDidMount() {
        super.componentDidMount(this);
        let ga = new GoogleAnalytics();
        ga.sendGTMEvent("PR_I_Q4A_Pets_Answered");
        // ga.sendGTMEvent("PR_I_Q4B_Emergency_Answered");
        ga.sendGTMEvent("PR_I_Q3_Doors_Answered");
    }
    elementButtons() {
        let { panic_button } = this.prices;

        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column">
                    <PriceButton
                        title="Ja"
                        logo="/img/price_calc/107 Panic Button.svg"
                        content="yes"
                        active={panic_button === "Ja"}
                        onclick={() => {
                            this.update("Ja");
                        }}
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Nein"
                        logo="/img/price_calc/000 Nein.svg"
                        content="no"
                        active={panic_button === "Nein"}
                        onclick={() => {
                            this.update("Nein");
                        }}
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Weiß nicht"
                        logo="/img/price_calc/000 Weiss nicht.svg"
                        content="dont_know"
                        active={panic_button === "Weiß ich nicht"}
                        onclick={() => {
                            this.update("Weiß ich nicht");
                        }}
                    />
                </div>
            </div>
        );
    }

    componentDidMount() {
        super.componentDidMount(this);
        let ga = new GoogleAnalytics();
        ga.sendGTMEvent("PR_I_Q3_Doors_Answered");
    }

    update(panic_button) {
        this.updated([
            { key: "panic_button", value: panic_button },
            { key: "contentWidgetStep", value: "alarmSmartphone" },
        ]);
    }
}

PriceWidgetAlarmPanicButton.propTypes = {
    updated: PropTypes.func.isRequired,
};

export default PriceWidgetAlarmPanicButton;
