import React from "react";
import PriceButton from "../PriceButton";
import PropTypes from "prop-types";
import GoogleAnalytics from "../../../helper/GoogleAnalytics";
import AbstractPriceWidget from "../AbstractPriceWidget";
import { Haus, Wohnung, Gewerbe, Buero } from "../elements/Constants";

class PriceStartWidgetAlarm extends AbstractPriceWidget {
    constructor(props) {
        super(props);

        if (this.prices.objectTypeWidget != null) {
            this.updated([
                { key: "nextButtonStep", value: "" },
                { key: "showNextButton", value: true },
            ]);
        }
        this.progress = 15;
        this.eventName = "calculator/a/PropertyType";
        this.nextStep = "alarmFloors";
        this.cssClass = "main";
        this.question = "Welche Immobilie möchten Sie sichern?";
    }

    componentDidMount() {
        let ga = new GoogleAnalytics();
        ga.sendEvent("preisrechner", "reached", "question 1");
    }

    elementButtons() {
        let { objectType } = this.props.prices;
        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column">
                    <PriceButton
                        title="Haus"
                        logo="/img/price_calc/001 Haus.svg"
                        content="house"
                        active={objectType === Haus}
                        onclick={() => {
                            this.update("house", Haus);
                        }}
                        // className="PR_I_Q1_Property_Answered"
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Wohnung"
                        logo="/img/price_calc/001 Wohnung.svg"
                        content="flat"
                        active={objectType === Wohnung}
                        onclick={() => {
                            this.update("flat", Wohnung);
                        }}
                        // className="PR_I_Q1_Property_Answered"
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Gewerbe"
                        logo="/img/price_calc/001 Gewerbe.svg"
                        content="business"
                        active={objectType === Gewerbe}
                        onclick={() => {
                            this.update("business", Gewerbe);
                        }}
                        // className="PR_I_Q1_Property_Answered"
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Büro"
                        logo="/img/price_calc/001 Buero.svg"
                        content="office"
                        active={objectType === Buero}
                        onclick={() => {
                            this.update("office", Buero);
                        }}
                        // className="PR_I_Q1_Property_Answered"
                    />
                </div>
            </div>
        );
    }

    update(objectTypeWidget, objectType) {
        console.log("upadte fired in priceStartWidgetAlarm");
        let ga = new GoogleAnalytics();
        // ga.sendGTMEvent("PR_F_Q1_Property_Answered");
        this.updated([
            { key: "objectType", value: objectType },
            { key: "objectTypeWidget", value: objectTypeWidget },
            { key: "contentWidgetStep", value: this.nextStep },
            { key: "coronaBanner", value: false },
            { key: "splitTesting", value: "220111-PriceCalculator-A" },
        ]);
    }
}

PriceStartWidgetAlarm.propTypes = {
    updated: PropTypes.func.isRequired,
};

export default PriceStartWidgetAlarm;
