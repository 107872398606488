import React from 'react';
import _ from 'lodash';
import PropTypes, { number } from "prop-types";
import AbstractPriceWidget from './AbstractPriceWidget';

export class PriceWidgetPostalCode extends AbstractPriceWidget {

    constructor(props) {
        super(props);
        this.progress = 98;
        let objectType = this.props.prices.objectType;
        this.questionHtml = '<h1>In welcher Region befindet sich ' + (objectType==='Wohnung' ? ' die ' : ' das ') + objectType + '?</h1><p>Erhalten Sie weitere Einbruchs-Statistiken zu Ihrer Nachbarschaft.</p>';
        this.cssClass = 'postal-code';
        let data = [
            { key: 'showNextButton', value: true },
            { key: 'nextButtonStep', value: 'checkout'}
        ];
        this.updated(data);
    }
    elementButtons() {
        return (
            <div className="columns postal-code">
                <div className="column">
                    <figure className="image">
                        <img src="/img/price_calc/map-deutschland-pin.png"/>
                    </figure>
                </div>
                <div className="column postal-code-form">
                    <div>
                        <p className="is-size-5-mobile">Postleitzahl</p>
                        <input 
                            className="input"
                            type="tel"
                            placeholder="z.B. 20457"
                            maxlength="10"
                            onChange={(event) => { this.updated([{ key: 'postalCode', value: event.target.value}]);}}/>
                    </div>
                </div>
            </div>
        );
    }
}

PriceWidgetPostalCode.propTypes = {
    updated: PropTypes.func.isRequired,
}

export default PriceWidgetPostalCode;
