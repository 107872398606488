import React from "react";
import PriceButton from "../PriceButton";
import PropTypes from "prop-types";
import AbstractPriceWidget from "../AbstractPriceWidget";
import ReactHtmlParser from "react-html-parser";
import GoogleAnalytics from "../../../helper/GoogleAnalytics";

class PriceWidgetVideoCCTVResolution extends AbstractPriceWidget {
    constructor(props) {
        super(props);
        this.progress = 65;
        this.cssClass = "cctv-resolution";
        this.question = "Wie hoch sollte die Auflösung der Kameras sein?";
        this.setEventName("CameraResolution");
        if (this.prices.cctv_resolution != null) {
            this.updated([
                { key: "nextButtonStep", value: "videoKI" },
                { key: "showNextButton", value: true },
            ]);
        }
    }
    componentDidMount() {
        super.componentDidMount(this);
        let ga = new GoogleAnalytics();
        ga.sendGTMEvent("PR_V_Q4_Form_Answered");
    }
    elementButtons() {
        let { cctv_resolution } = this.prices;
        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column">
                    <PriceButton
                        title={ReactHtmlParser("Standard")}
                        logo="/img/price_calc/207 HD.svg"
                        content="Standard"
                        active={cctv_resolution === "2 Megapixel (HD Qualität)"}
                        onclick={() => {
                            this.update("2 Megapixel (HD Qualität)");
                        }}
                        className="PR_V_Q5_Quality_Answered"
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Hoch"
                        logo="/img/price_calc/207 4MP.svg"
                        content="Hoch"
                        active={cctv_resolution === "4 Megapixel"}
                        onclick={() => {
                            this.update("4 Megapixel");
                        }}
                        className="PR_V_Q5_Quality_Answered"
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title={ReactHtmlParser("Sehr hoch")}
                        logo="/img/price_calc/207 4K UHD.svg"
                        content="Sehr Hoch"
                        active={
                            cctv_resolution === "8 Megapixel (4K UHD Qualität)"
                        }
                        onclick={() => {
                            this.update("8 Megapixel (4K UHD Qualität)");
                        }}
                        className="PR_V_Q5_Quality_Answered"
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Weiß nicht"
                        logo="/img/price_calc/000 Weiss nicht.svg"
                        content="Weiß nicht"
                        active={cctv_resolution === "Weiß nicht"}
                        onclick={() => {
                            this.update("Weiß nicht");
                        }}
                        className="PR_V_Q5_Quality_Answered"
                    />
                </div>
            </div>
        );
    }
    update(cctv_resolution) {
        this.updated([
            { key: "cctv_resolution", value: cctv_resolution },
            { key: "contentWidgetStep", value: "videoKI" },
        ]);
    }
}

PriceWidgetVideoCCTVResolution.propTypes = {
    updated: PropTypes.func.isRequired,
};

export default PriceWidgetVideoCCTVResolution;
