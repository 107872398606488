import React from "react";
import PropTypes from "prop-types";
import PriceButton from "../PriceButton";
import AbstractPriceWidget from "../AbstractPriceWidget";
import GoogleAnalytics from "../../../helper/GoogleAnalytics";

export class PriceWidgetAlarmSmartphone extends AbstractPriceWidget {
    constructor(props) {
        super(props);
        let { smartphone } = this.prices;
        this.progress = 85;
        this.cssClass = "smartphone";
        this.setEventName("Smartphone");
        this.question =
            "Möchten Sie gerne per Smartphone App auf Ihre Alarmanlage zugreifen können?";
        this.nextStep = "alarmCrossSelling";
        this.updated([{ key: "showSkip", value: false }]);
        if (smartphone !== null) {
            this.updated([
                { key: "nextButtonStep", value: this.nextStep },
                { key: "showNextButton", value: true },
            ]);
        }
    }
    componentDidMount() {
        super.componentDidMount(this);
        let ga = new GoogleAnalytics();
        ga.sendGTMEvent("PR_I_Q4B_Emergency_Answered");
    }
    elementButtons() {
        let { smartphone } = this.prices;
        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column">
                    <PriceButton
                        title="Ja"
                        logo="/img/price_calc/208 Smartphone Video.svg"
                        content="yes"
                        active={smartphone === "Ja"}
                        onclick={() => {
                            this.update("Ja");
                        }}
                        className="PR_I_Q6_Smartphone_Answered"
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Nein"
                        logo="/img/price_calc/000 Nein.svg"
                        content="no"
                        active={smartphone === "Nein"}
                        onclick={() => {
                            this.update("Nein");
                        }}
                        className="PR_I_Q6_Smartphone_Answered"
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Weiß nicht"
                        logo="/img/price_calc/000 Weiss nicht.svg"
                        content="dont_know"
                        active={smartphone === "Weiß ich nicht"}
                        onclick={() => {
                            this.update("Weiß ich nicht");
                        }}
                        className="PR_I_Q6_Smartphone_Answered"
                    />
                </div>
            </div>
        );
    }

    update(smartphone) {
        this.updated([
            { key: "smartphone", value: smartphone },
            { key: "contentWidgetStep", value: this.nextStep },
        ]);
    }
}

PriceWidgetAlarmSmartphone.propTypes = {
    updated: PropTypes.func.isRequired,
};

export default PriceWidgetAlarmSmartphone;
