import React from "react";
import PriceCalculator from "./PriceCalculator";
import Ausgezeichnet from "../img/2023-ausgezeichnet-kunden-sagen-sehr-gut.png";
// import AusgezeichnetMobile from "../img/2022-ausgezeichnet-kunden-sagen-sehr-gut-mobile.png";
import AusgezeichnetMobile from "../img/2023-ausgezeichnet-kunden-sagen-sehr-gut.png";
import Certificates from "../img/certificates.png";
import MIG from "../img/made-in-germany.png";
import BEIDER from "../img/made-in-germany.png";
import NotificationBanner from "./NotificationBanner";
import PriceCalcNavigation from "./PriceCalculator/PriceCalcNavigation";
import Cookies from "js-cookie";
export default class DefaultHero extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPriceCalcNavigation:
                typeof this.props.context.showPriceCalculator === "undefined"
                    ? true
                    : this.props.context.showPriceCalculator,
            showNav: false,
        };
    }
    handleNewQuestionInHero = () => {
        const { lastStep, contentWidgetStep } = Cookies.getJSON("state");
        if (
            contentWidgetStep !== lastStep[lastStep.length - 1] &&
            lastStep.length < 4 &&
            this.state.showPriceCalcNavigation === false
        ) {
            this.setState({
                showPriceCalcNavigation: true,
            });
        } else if (
            lastStep[lastStep.length - 1] === "thanks" &&
            this.state.showPriceCalcNavigation === false
        ) {
            this.setState({
                showPriceCalcNavigation: true,
            });
        } else if (
            lastStep.length >= 4 &&
            lastStep[lastStep.length - 1] !== "thanks" &&
            this.state.showPriceCalcNavigation === true
        ) {
            this.setState({
                showPriceCalcNavigation: false,
            });
        }
    };

    render() {
        let title = typeof this.props.context.title === "undefined";
        let backgroundColor =
            typeof this.props.context.backgroundColor === "undefined"
                ? ""
                : this.props.context.backgroundColor;
        if (typeof window !== `undefined` && window.innerWidth > 768) {
            backgroundColor = "";
        }
        let image = this.props.context.image;
        let image768 =
            typeof this.props.context.image768 === "undefined"
                ? image
                : this.props.context.image768;
        let image1024 =
            typeof this.props.context.image1024 === "undefined"
                ? image
                : this.props.context.image1024;
        let image1216 =
            typeof this.props.context.image1216 === "undefined"
                ? image
                : this.props.context.image1216;
        let image1408 =
            typeof this.props.context.image1408 === "undefined"
                ? image
                : this.props.context.image1408;
        let showPriceCalculator =
            typeof this.props.context.showPriceCalculator === "undefined"
                ? true
                : this.props.context.showPriceCalculator;
        let product = this.props.context.product;
        let showBottom =
            typeof this.props.context.showBottom === "undefined"
                ? false
                : this.props.context.showBottom;
        let showText =
            typeof this.props.context.showText === "undefined"
                ? null
                : this.props.context.showText;
        let showElement =
            typeof this.props.context.showElement === "undefined"
                ? null
                : this.props.context.showElement;
        let showHeroMobile =
            typeof this.props.context.showHeroMobile === "undefined"
                ? false
                : this.props.context.showHeroMobile;
        let defaultCssClass =
            typeof this.props.context.defaultCssClass === "undefined"
                ? ""
                : this.props.context.defaultCssClass;
        let onlyDesktop =
            typeof this.props.context.onlyDesktop === "undefined"
                ? ""
                : this.props.context.onlyDesktop;
        let showNotification =
            typeof this.props.context.showNotification === "undefined"
                ? true
                : this.props.context.defaultCssClass;
        console.log("showNotification", showNotification);
        let windowWidth =
            typeof window != "undefined" ? window.innerWidth : 1500;
        let windowHeight =
            typeof window != "undefined" ? window.innerHeight : 1500;

        if (typeof window !== "undefined" && typeof document !== "undefined") {
            let path = window.location.pathname;
            path = path.replace(/\//g, "");
            console.log("load script firedddddddddddddddddddddddddddd", path);
            const removeArray = [
                "schliessanlagenpreisrechner",
                "videoueberwachungpreisrechner",
                "alarmanlagenpreisrechner",
                "brandmeldeanlagenpreisrechner",
                "preisrechner",
            ];
            if (removeArray.includes(path)) {
                this.state.showNav = true;
            } else {
                this.state.showNav = false;
            }
            if (
                removeArray.includes(path) &&
                window.innerWidth <= 768 &&
                window.innerWidth >= 500
            ) {
                setTimeout(() => {
                    const heroBody =
                        document.getElementsByClassName("hero-body")[0];
                    console.log("heroBody:", heroBody);
                    heroBody.style.marginTop = "-72px";
                }, 100);
            }
            if (removeArray.includes(path) && window.innerWidth < 500) {
                setTimeout(() => {
                    const heroBody =
                        document.getElementsByClassName("hero-body")[0];
                    console.log("heroBody:", heroBody);
                    heroBody.style.marginTop = "-35px";
                }, 100);
            }
        }
        return (
            <div
                className={
                    "hero is-light default-hero  default-hero-home " +
                    defaultCssClass
                }
            >
                <div className={"hero-image " + onlyDesktop}>
                    <picture>
                        <source media="(max-width:768px)" srcSet={image768} />
                        <source media="(max-width:1024px)" srcSet={image1024} />
                        <source media="(max-width:1216px)" srcSet={image1216} />
                        <source media="(min-width:1217px)" srcSet={image1408} />
                        <img
                            src={image}
                            style={{ width: "auto" }}
                            alt={title}
                            title={title}
                        />
                    </picture>
                </div>
                <div className="hero-body">
                    <div className="container has-text-centered">
                        <div
                            className={`hero-box ${backgroundColor}`}
                            // style={{
                            //     backgroundColor: backgroundColor,
                            // }}
                        >
                            {showElement}

                            {showPriceCalculator === true && (
                                <PriceCalculator
                                    product={product}
                                    handleNewQuestionInHero={
                                        this.handleNewQuestionInHero
                                    }
                                    showNavigation={
                                        this.state.showPriceCalcNavigation
                                    }
                                />
                            )}
                            {this.state.showPriceCalcNavigation &&
                                this.state.showNav && (
                                    <div className="is-hidden-mobile">
                                        <PriceCalcNavigation
                                            product={product}
                                            city={this.props.context.city}
                                        />
                                    </div>
                                )}
                            {showText && (
                                <div>
                                    <h1 className="h1">{showText}</h1>
                                </div>
                            )}
                            {showHeroMobile && (
                                <div className="hero-mobile-body">&nbsp;</div>
                            )}
                            {/* {showElement} */}

                            {/* { showBottom && !showPriceCalculator && windowWidth < 785 && windowWidth > 340 && windowHeight > 700 && */}
                            {showBottom &&
                                !showPriceCalculator &&
                                showNotification &&
                                windowHeight > 650 && (
                                    <NotificationBanner className="is-hidden-desktop show_nfbanner" />
                                )}
                        </div>
                    </div>
                </div>
                {showBottom && (
                    <div className="hero-bottom">
                        <div className="container">
                            <div className="columns is-mobile">
                                <div className="column is-one-fifth-tablet left">
                                    <a
                                        href="https://www.ausgezeichnet.org/bewertungen-meinalarm24.de-5LNU6F"
                                        target="_blank"
                                        rel="nofollow"
                                    >
                                        <picture>
                                            <source
                                                srcSet={AusgezeichnetMobile}
                                                media="(max-width:768px)"
                                            />
                                            <img
                                                src={Ausgezeichnet}
                                                alt="Ausgezeichnet.org Sehr Gut"
                                                title="Ausgezeichnet.org Sehr Gut"
                                            />
                                        </picture>
                                    </a>
                                </div>
                                <div className="column has-text-centered middle">
                                    <a
                                        href="/img/bhe-mitgliedsbescheinigung.jpg"
                                        target="_blank"
                                        className="is-hidden-mobile"
                                        rel="noopener noreferrer"
                                    >
                                        <picture>
                                            <source
                                                srcSet="/img/empty.gif"
                                                media="(max-width:768px)"
                                            />
                                            <img
                                                style={{ height: "120px" }}
                                                src={Certificates}
                                                alt="BHE Bundesverband fuer Sicherheitstechnik e.V"
                                                title="BHE Bundesverband fuer Sicherheitstechnik e.V"
                                            />
                                        </picture>
                                    </a>
                                    <a
                                        href="/img/bhe-mitgliedsbescheinigung.jpg"
                                        target="_blank"
                                        className="only-mobile"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src={MIG}
                                            className="is-pulled-right"
                                            alt="Made in germany"
                                            title="Made in germany"
                                        />
                                    </a>
                                </div>
                                <div className="column is-hidden-mobile is-one-fifth right">
                                    <img
                                        className="is-pulled-right"
                                        src={BEIDER}
                                        alt=""
                                        title=""
                                    />
                                    {/* <img
                                        className="is-pulled-right"
                                        src={MIG}
                                        alt="Made in Germany"
                                        title="Made in Germany"
                                    /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
