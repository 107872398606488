import React from "react";
import PropTypes from "prop-types";
import PriceButton from "../PriceButton";
import AbstractPriceWidget from "../AbstractPriceWidget";
import GoogleAnalytics from "../../../helper/GoogleAnalytics";

export class PriceWidgetFireAlarmRequirements extends AbstractPriceWidget {
    constructor(props) {
        super(props);
        this.progress = 90;
        this.cssClass = "smartphone";
        this.questionHtml =
            "<h1>Liegen bereits Anforderungen von Behörden vor?</h1><p>Zum Beispiel DIN 14675, VdS 2095, DIN VDE 0833-2, usw.</h3>";
        this.nextStep = "fireAlarmCarryProcess";
        this.eventName = "calculator/f/Requirements";
        this.updated([
            { key: "nextButtonStep", value: "fireAlarmCarryProcess" },
            { key: "showNextButton", value: true },
        ]);
    }
    componentDidMount() {
        super.componentDidMount(this);
        let ga = new GoogleAnalytics();
        ga.sendGTMEvent("PR_F_Q4_Size_Answered");
    }
    elementButtons() {
        let { fireAlarmRequirements } = this.prices;
        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column">
                    <PriceButton
                        title="Ja"
                        logo="/img/price_calc/051 Ja.svg"
                        content="yes"
                        active={fireAlarmRequirements === "Ja"}
                        onclick={() => {
                            this.update("Ja");
                        }}
                        className="PR_F_Q5_Requirements_Answered"
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Nein"
                        logo="/img/price_calc/052 Nein.svg"
                        content="no"
                        active={fireAlarmRequirements === "Nein"}
                        onclick={() => {
                            this.update("Nein");
                        }}
                        className="PR_F_Q5_Requirements_Answered"
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Weiß nicht"
                        logo="/img/price_calc/053 Weiss nicht.svg"
                        content="dont_know"
                        active={fireAlarmRequirements === "Weiß ich nicht"}
                        onclick={() => {
                            this.update("Weiß ich nicht");
                        }}
                        className="PR_F_Q5_Requirements_Answered"
                    />
                </div>
            </div>
        );
    }

    update(fireAlarmRequirements) {
        this.updated([
            { key: "fireAlarmRequirements", value: fireAlarmRequirements },
            { key: "contentWidgetStep", value: this.nextStep },
        ]);
    }
}

PriceWidgetFireAlarmRequirements.propTypes = {
    updated: PropTypes.func.isRequired,
};

export default PriceWidgetFireAlarmRequirements;
