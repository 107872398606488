import React from "react";
import PropTypes from "prop-types";
import AbstractPriceWidget from "../AbstractPriceWidget";
import GoogleAnalytics from "../../../helper/GoogleAnalytics";

class PriceWidgetFireAlarmRealEstateSize extends AbstractPriceWidget {
    constructor(props) {
        super(props);
        this.progress = 80;
        this.cssClass = "main";
        this.eventName = "calculator/f/SquareMeters";
        this.question = "Welche Größe hat die abzusichernde Immobilie?";
        this.nextStep = "fireAlarmRequirements";
        this.updated([
            { key: "showNextButton", value: true },
            { key: "nextButtonStep", value: this.nextStep },
        ]);
    }

    componentDidMount() {
        super.componentDidMount(this);
        let ga = new GoogleAnalytics();
        ga.sendGTMEvent("PR_F_Q3_Floors_Answered");
    }
    elementButtons() {
        let { fireAlarmRealEstateSize } = this.props.prices;
        let choices = [
            "100m² oder weniger",
            "100-300m²",
            "300-500m²",
            "500-1.000m²",
            "1.000-2.000m²",
            "2.000-3.000m²",
            "3.000-5.000m²",
            "5.000-10.000m²",
            "10.000m² oder mehr",
        ];
        let elements = [];
        for (var i = 0; i <= choices.length; i++) {
            elements.push(
                <option key={i} value={choices[i]}>
                    {choices[i]}
                </option>
            );
        }
        return (
            <div className="columns is-gapless-desktop">
                <div className="column price-range-text">
                    <strong>Größe der Immobilie :</strong>
                </div>
                <div className="column price-range">
                    <div className="select">
                        <select
                            onChange={(event) => {
                                this.updated([
                                    {
                                        key: "fireAlarmRealEstateSize",
                                        value: event.target.value,
                                    },
                                ]);
                            }}
                            defaultValue={fireAlarmRealEstateSize}
                        >
                            {elements}
                        </select>
                    </div>
                </div>
                <div className="column price-range PR_F_Q4_Size_Answered">
                    <button
                        className="button"
                        onClick={() => {
                            this.updated([
                                {
                                    key: "fireAlarmRealEstateSize",
                                    value: "Weiß nicht",
                                },
                                {
                                    key: "contentWidgetStep",
                                    value: this.nextStep,
                                },
                            ]);
                        }}
                    >
                        Weiß nicht
                    </button>
                </div>
            </div>
        );
    }
}

PriceWidgetFireAlarmRealEstateSize.propTypes = {
    updated: PropTypes.func.isRequired,
};

export default PriceWidgetFireAlarmRealEstateSize;
