import React from "react";
import PropTypes from "prop-types";
import PriceButton from "../PriceButton";
import AbstractPriceWidget from "../AbstractPriceWidget";
import GoogleAnalytics from "../../../helper/GoogleAnalytics";

export class PriceWidgetFireAlarmAlreadyEquiped extends AbstractPriceWidget {
    constructor(props) {
        super(props);
        this.progress = 40;
        this.cssClass = "smartphone";
        this.eventName = "calculator/f/ExistingFireAlarm";
        this.question =
            "Ist in der abzusichernden Immobilie bereits eine Brandmeldeanlage vorhanden?";
        this.nextStep = "fireAlarmFloors";
        this.updated([
            { key: "nextButtonStep", value: this.nextStep },
            { key: "showNextButton", value: true },
        ]);
    }
    componentDidMount() {
        super.componentDidMount(this);
        let ga = new GoogleAnalytics();
        ga.sendGTMEvent("PR_F_Q1_Property_Answered");
    }
    elementButtons() {
        let { fireAlarmAlreadyEquiped } = this.prices;
        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column">
                    <PriceButton
                        title="Ja"
                        logo="/img/price_calc/021 Ja.svg"
                        content="yes"
                        active={fireAlarmAlreadyEquiped === "Ja"}
                        onclick={() => {
                            this.update("Ja");
                        }}
                        className="PR_F_Q2_ExistingSystem_Answered"
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Nein"
                        logo="/img/price_calc/022 Nein.svg"
                        content="no"
                        active={fireAlarmAlreadyEquiped === "Nein"}
                        onclick={() => {
                            this.update("Nein");
                        }}
                        className="PR_F_Q2_ExistingSystem_Answered"
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Weiß nicht"
                        logo="/img/price_calc/023 Weiss nicht.svg"
                        content="dont_know"
                        active={fireAlarmAlreadyEquiped === "Weiß ich nicht"}
                        onclick={() => {
                            this.update("Weiß ich nicht");
                        }}
                        className="PR_F_Q2_ExistingSystem_Answered"
                    />
                </div>
            </div>
        );
    }

    update(fireAlarmAlreadyEquiped) {
        this.updated([
            { key: "fireAlarmAlreadyEquiped", value: fireAlarmAlreadyEquiped },
            { key: "contentWidgetStep", value: this.nextStep },
        ]);
    }
}

PriceWidgetFireAlarmAlreadyEquiped.propTypes = {
    updated: PropTypes.func.isRequired,
};

export default PriceWidgetFireAlarmAlreadyEquiped;
