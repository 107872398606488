import React from "react";
import PriceButton from "../PriceButton";
import PropTypes from "prop-types";
import GoogleAnalytics from "../../../helper/GoogleAnalytics";
import AbstractPriceWidget from "../AbstractPriceWidget";
import { Haus, Wohnung, Gewerbe, Buero } from "../elements/Constants";

class PriceStartWidgetVideo extends AbstractPriceWidget {
    constructor(props) {
        super(props);

        if (this.prices.objectTypeWidget != null) {
            this.updated([
                { key: "nextButtonStep", value: "" },
                { key: "showNextButton", value: true },
            ]);
        }
        this.progress = 15;
        this.eventName = "calculator/v/PropertyType";
        this.nextStep = "videoCctv";
        this.cssClass = "main";
        this.question = "Welche Immobilie möchten Sie sichern?";
    }

    componentDidMount() {
        super.componentDidMount(this);
        let ga = new GoogleAnalytics();
        ga.sendEvent(
            "preisrechnerPriceStartWidgetVideo",
            "reached",
            "question 1"
        );    
        ga.sendEvent("preisrechner", "reached", "question 1");
    }

    elementButtons() {
        let { objectType } = this.props.prices;
        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column">
                    <PriceButton
                        title="Haus"
                        logo="/img/price_calc/001 Haus.svg"
                        content="house"
                        active={objectType === Haus}
                        onclick={() => {
                            this.update("house", Haus);
                        }}
                        className="PR_V_Q1_Property_Answered"
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Wohnung"
                        logo="/img/price_calc/001 Wohnung.svg"
                        content="flat"
                        active={objectType === Wohnung}
                        onclick={() => {
                            this.update("flat", Wohnung);
                        }}
                        className="PR_V_Q1_Property_Answered"
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Gewerbe"
                        logo="/img/price_calc/001 Gewerbe.svg"
                        content="business"
                        active={objectType === Gewerbe}
                        onclick={() => {
                            this.update("business", Gewerbe);
                        }}
                        className="PR_V_Q1_Property_Answered"
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Büro"
                        logo="/img/price_calc/001 Buero.svg"
                        content="office"
                        active={objectType === Buero}
                        onclick={() => {
                            this.update("office", Buero);
                        }}
                        className="PR_V_Q1_Property_Answered"
                    />
                </div>
            </div>
        );
    }

    update(objectTypeWidget, objectType) {
        this.updated([
            { key: "objectType", value: objectType },
            { key: "objectTypeWidget", value: objectTypeWidget },
            { key: "contentWidgetStep", value: this.nextStep },
            { key: "coronaBanner", value: false },
        ]);
    }
}

PriceStartWidgetVideo.propTypes = {
    updated: PropTypes.func.isRequired,
};

export default PriceStartWidgetVideo;
