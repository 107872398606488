import React from 'react';

const PriceProgressBar = ({ value, maximum, mainCssClass }) => {
    return (
        <div className={'columns price-progressbar ' + mainCssClass || ''}>
            <div className="column column-left">
                <progress className="progress" max={maximum} value={value}>{value}%</progress>
            </div>
            <div className="column is-narrow column-right is-hidden-mobile">
                <span>{value}%</span>
            </div>
        </div>
    );
}

export default PriceProgressBar;
