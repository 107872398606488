import React from "react";
import PriceButtonCheckBox from "../PriceButtonCheckBox";
import PropTypes from "prop-types";
import AbstractPriceWidget from "../AbstractPriceWidget";
import {
    Videoueberwachung,
    Tuerschloss,
    Brand,
    Haus,
    Wohnung,
} from "../elements/Constants";
import GoogleAnalytics from "../../../helper/GoogleAnalytics";

class PriceWidgetAlarmCrossSelling extends AbstractPriceWidget {
    constructor(props) {
        super(props);
        // this.nextStep = 'kfw';
        this.nextStep = "checkout";
        if (this.isObjectTypeBusiness()) {
            this.nextStep = "checkout";
        }
        this.updated([
            { key: "showNextButton", value: true },
            { key: "nextButtonStep", value: this.nextStep },
            { key: "showSkip", value: true },
        ]);
        this.progress = 90;
        this.setEventName("crossSelling");
        this.cssClass = "crossSelling";
        this.questionHtml =
            "<h3>Haben Sie Interesse an folgenden Erweiterungen?</h3><p>Mehrfachauswahl möglich</p>";
    }

    componentDidMount() {
        super.componentDidMount(this);
        let ga = new GoogleAnalytics();
        ga.sendGTMEvent("PR_I_Q5_Smartphone_Answered");
    }
    elementButtons() {
        let { crossSelling, objectType } = this.props.prices;
        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column">
                    <PriceButtonCheckBox
                        title="Videoanlage"
                        logo="/img/price_calc/002 Videoanlage.svg"
                        content={Videoueberwachung}
                        active={crossSelling.includes(Videoueberwachung)}
                        onclick={() => {
                            this.update(crossSelling, Videoueberwachung);
                        }}
                        className="PR_I_Q7_Smartphone_Answered"
                    />
                </div>
                <div className="column PR_I_Q7_Smartphone_Answered">
                    <PriceButtonCheckBox
                        title={
                            objectType === Haus || objectType === Wohnung
                                ? "Elektronische Türschlösser "
                                : "Schließanlage "
                        }
                        logo="/img/price_calc/582 Elektronisches Türschloss.svg"
                        content={Tuerschloss}
                        active={crossSelling.includes(Tuerschloss)}
                        onclick={() => {
                            this.update(crossSelling, Tuerschloss);
                        }}
                    />
                </div>
                <div className="column PR_I_Q7_Smartphone_Answered">
                    <PriceButtonCheckBox
                        title="Brandmeldeanlage"
                        logo="/img/price_calc/581 Brandmelder.svg"
                        content={Brand}
                        active={crossSelling.includes(Brand)}
                        onclick={() => {
                            this.update(crossSelling, Brand);
                        }}
                    />
                </div>
            </div>
        );
    }

    update(crossSelling, product) {
        const index = crossSelling.indexOf(product);
        if (index > -1) {
            crossSelling.splice(index, 1);
        } else {
            crossSelling.push(product);
        }
        this.updated([{ key: "crossSelling", value: crossSelling }]);
    }
}

PriceWidgetAlarmCrossSelling.propTypes = {
    updated: PropTypes.func.isRequired,
};

export default PriceWidgetAlarmCrossSelling;
