import React from "react";
import PropTypes from "prop-types";
import PriceButton from "../PriceButton";
import AbstractPriceWidget from "../AbstractPriceWidget";
import GoogleAnalytics from "../../../helper/GoogleAnalytics";

export class PriceWidgetFireAlarmCarryProcess extends AbstractPriceWidget {
    constructor(props) {
        super(props);
        this.progress = 95;
        this.cssClass = "smartphone";
        this.questionHtml =
            "<h1>Wünschen Sie, dass wir den kompletten Prozess für Ihre neue Bandmeldeanlage übernehmen?</h1><p>Projektierung, Installation, Abnahme eines unabhängigen Sachverständigen usw.</h3>";
        this.eventName = "calculator/f/EntireProcess";
        this.nextStep = "checkout";
        this.updated([
            { key: "nextButtonStep", value: this.nextStep },
            { key: "showButtons", value: false },
        ]);
    }

    componentDidMount() {
        super.componentDidMount(this);
        let ga = new GoogleAnalytics();
        ga.sendGTMEvent("PR_F_Q5_Requirements_Answered");
    }
    elementButtons() {
        let { fireAlarmCarryProcess } = this.prices;
        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column">
                    <PriceButton
                        title="Ja"
                        logo="/img/price_calc/061 Ja.svg"
                        content="yes"
                        active={fireAlarmCarryProcess === "Ja"}
                        onclick={() => {
                            this.update("Ja");
                        }}
                        className="PR_F_Q6_Process_Answered "
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Nein"
                        logo="/img/price_calc/062 Nein.svg"
                        content="no"
                        active={fireAlarmCarryProcess === "Nein"}
                        onclick={() => {
                            this.update("Nein");
                        }}
                        className="PR_F_Q6_Process_Answered "
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Weiß nicht"
                        logo="/img/price_calc/063 Weiss nicht.svg"
                        content="dont_know"
                        active={fireAlarmCarryProcess === "Weiß ich nicht"}
                        onclick={() => {
                            this.update("Weiß ich nicht");
                        }}
                        className="PR_F_Q6_Process_Answered "
                    />
                </div>
            </div>
        );
    }

    update(fireAlarmCarryProcess) {
        this.updated([
            { key: "fireAlarmCarryProcess", value: fireAlarmCarryProcess },
            { key: "contentWidgetStep", value: this.nextStep },
        ]);
    }
}

PriceWidgetFireAlarmCarryProcess.propTypes = {
    updated: PropTypes.func.isRequired,
};

export default PriceWidgetFireAlarmCarryProcess;
