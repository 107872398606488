import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import AbstractPriceWidget from "../AbstractPriceWidget";
import GoogleAnalytics from "../../../helper/GoogleAnalytics";

export class PriceWidgetVideoCCTVCount extends AbstractPriceWidget {
    constructor(props) {
        super(props);
        this.progress = 35;
        this.cssClass = "cctv_count";
        this.question = "Wie viele Videokameras benötigen Sie?";
        this.setEventName("AmountOfCameras");
        if (this.prices.cctv_count !== null) {
            let data = [
                { key: "showNextButton", value: true },
                { key: "nextButtonStep", value: "videoCctvType" },
            ];
            this.updated(data);
        }
    }
    componentDidMount() {
        super.componentDidMount(this);
        let ga = new GoogleAnalytics();
        ga.sendGTMEvent("PR_V_Q2_Areas_Answered");
    }
    elementButtons() {
        var { cctv_count } = this.prices;
        let elements = [];
        for (var i = 1; i <= 20; i++) {
            elements.push(
                <option key={i} value={i}>
                    {i}
                </option>
            );
        }
        return (
            <div className="columns is-gapless-desktop">
                <div className="column price-range-text">
                    <strong>Videokameras: </strong>
                </div>
                <div className="column price-range ">
                    <div className="select">
                        <select
                            onChange={(event) => {
                                this.updated([
                                    {
                                        key: "cctv_count",
                                        value: event.target.value,
                                    },
                                ]);
                            }}
                            defaultValue={cctv_count}
                        >
                            {elements}
                        </select>
                    </div>
                </div>
                <div className="column price-range PR_V_Q3_Amount_Answered">
                    <button
                        className="button"
                        onClick={() => {
                            this.updated([
                                { key: "cctv_count", value: "Weiß nicht" },
                                {
                                    key: "contentWidgetStep",
                                    value: "videoCctvType",
                                },
                            ]);
                        }}
                    >
                        Weiß nicht
                    </button>
                </div>
            </div>
        );
    }
}

PriceWidgetVideoCCTVCount.propTypes = {
    updated: PropTypes.func.isRequired,
};

export default PriceWidgetVideoCCTVCount;
