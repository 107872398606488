import React from 'react';
import PropTypes from "prop-types";
import PriceButton from './PriceButton';
import AbstractPriceWidget from './AbstractPriceWidget';
import { Videoueberwachung, Alarmanlage } from './elements/Constants';

export class PriceWidgetBudget extends AbstractPriceWidget {

    constructor(props) {
        super(props);
        this.progress = 93;
        this.cssClass = 'budget';
        if (this.props.prices.securitySystem === Alarmanlage) {
            this.offerType = 'Alarmanlage';
        } else if (this.props.prices.securitySystem === Videoueberwachung) {
            this.offerType = 'Videoanlage';
        } else {
            this.offerType = 'Alarmanlage und Videoanlage';
        }
        this.question = 'Welches Budget haben Sie für die neue ' + this.offerType + ' eingeplant?';
        this.nextButtonStep = this.isObjectTypeBusiness() ? 'postalCode' : 'kfw';
        if (this.prices.budget != null) {
            this.updated([
                { key: 'nextButtonStep', value: this.nextButtonStep },
                { key: 'showNextButton', value: true }
            ]);
        }
    }
    elementButtons() {
        let { budget } = this.props.prices;
        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column"><PriceButton
                    title="1.000€ oder weniger"
                    logo='/img/price_calc/301 1.000€ oder weniger.svg'
                    content="Low"
                    active={budget === 1000}
                    onclick={() => { this.update(1500); }} /></div>
                <div className="column"><PriceButton
                    title="1.000€ bis 2.000€"
                    logo='/img/price_calc/302 1.000€ bis 2.000€.svg'
                    content="2000"
                    active={budget === 2000}
                    onclick={() => { this.update(2000); }} /></div>
                <div className="column"><PriceButton
                    title="2.000€ bis 4.000€"
                    logo='/img/price_calc/303 2.000€ bis 3.000€.svg'
                    content="High"
                    active={budget === 4000}
                    onclick={() => { this.update(4000); }} /></div>
                <div className="column"><PriceButton
                    title="4.000€ oder mehr"
                    logo='/img/price_calc/304 3.000€ oder mehr.svg'
                    content="High"
                    active={budget === 6000}
                    onclick={() => { this.update(6000); }} /></div>
            </div>
        );
    }

    update(budget) {
            this.updated([
                { key: 'budget', value: budget },
                { key: 'contentWidgetStep', value: this.nextButtonStep }
            ]);
    }
}

PriceWidgetBudget.propTypes = {
    updated: PropTypes.func.isRequired
}

export default PriceWidgetBudget;