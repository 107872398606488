import React from "react";
import PropTypes from "prop-types";
import PriceButton from "../PriceButton";
import AbstractPriceWidget from "../AbstractPriceWidget";
import { Gewerbe, Buero } from "../elements/Constants";
import GoogleAnalytics from "../../../helper/GoogleAnalytics";

export class PriceWidgetDoorLockUserCount extends AbstractPriceWidget {
    constructor(props) {
        super(props);
        this.progress = 60;
        this.cssClass = "DoorLockUserCount";
        this.question = "Wie viele Personen sollen Zugang erhalten? ";
        this.eventName = "calculator/d/AmountOfKeys";
        this.nextButtonStep = "doorLockSmartphone";
        if (this.prices.doorLockUserCount != null) {
            this.updated([
                { key: "nextButtonStep", value: this.nextButtonStep },
                { key: "showNextButton", value: true },
            ]);
        }
    }
    componentDidMount() {
        super.componentDidMount(this);
        let ga = new GoogleAnalytics();
        ga.sendGTMEvent("PR_D_Q2_Amount_Answered");
    }
    elementButtons() {
        let { doorLockUserCount, objectType } = this.props.prices;
        const isBusiness = objectType === Gewerbe || objectType === Buero;
        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column ">
                    <PriceButton
                        title={
                            isBusiness ? "1 bis 5 Personen" : "1 bis 2 Personen"
                        }
                        logo="/img/price_calc/431 1 to 5.svg"
                        content={
                            isBusiness ? "1 bis 5 Personen" : "1 bis 2 Personen"
                        }
                        active={
                            doorLockUserCount === "1-5" ||
                            doorLockUserCount === "1-2"
                        }
                        onclick={() => {
                            this.update(isBusiness ? "1-5" : "1-2");
                        }}
                        className="PR_D_Q3_Users_Answered"
                    />
                </div>
                <div className="column ">
                    <PriceButton
                        title={
                            isBusiness
                                ? "6 bis 15 Personen"
                                : "3 bis 5 Personen"
                        }
                        logo="/img/price_calc/432 6 to 10.svg"
                        content={
                            isBusiness
                                ? "6 bis 15 Personen"
                                : "3 bis 5 Personen"
                        }
                        active={
                            doorLockUserCount === "6-15" ||
                            doorLockUserCount === "3-5"
                        }
                        onclick={() => {
                            this.update(isBusiness ? "6-15" : "3-5");
                        }}
                        className="PR_D_Q3_Users_Answered"
                    />
                </div>
                <div className="column ">
                    <PriceButton
                        title={
                            isBusiness
                                ? "16 bis 50 Personen"
                                : "6 bis 10 Personen"
                        }
                        logo="/img/price_calc/433 11 to 20.svg"
                        content={
                            isBusiness
                                ? "16 bis 50 Personen"
                                : "6 bis 10 Personen"
                        }
                        active={
                            doorLockUserCount === "16-50" ||
                            doorLockUserCount === "6-10"
                        }
                        onclick={() => {
                            this.update(isBusiness ? "16-50" : "6-10");
                        }}
                        className="PR_D_Q3_Users_Answered"
                    />
                </div>
                <div className="column ">
                    <PriceButton
                        title={
                            isBusiness
                                ? "Mehr als 50 Personen"
                                : "Mehr als 10 Personen"
                        }
                        logo="/img/price_calc/434 21 or more.svg"
                        content={
                            isBusiness
                                ? "Mehr als 50 Personen"
                                : "Mehr als 10 Personen"
                        }
                        active={
                            doorLockUserCount === "50+" ||
                            doorLockUserCount === "10+"
                        }
                        onclick={() => {
                            this.update(isBusiness ? "50+" : "10+");
                        }}
                        className="PR_D_Q3_Users_Answered"
                    />
                </div>
            </div>
        );
    }

    update(doorLockUserCount) {
        this.updated([
            { key: "doorLockUserCount", value: doorLockUserCount },
            { key: "contentWidgetStep", value: this.nextButtonStep },
        ]);
    }
}

PriceWidgetDoorLockUserCount.propTypes = {
    updated: PropTypes.func.isRequired,
};

export default PriceWidgetDoorLockUserCount;
