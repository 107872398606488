import React from "react";

const PriceButton = ({ title, logo, onclick, content, active, className }) => {
    let css = active ? "icon icon-active" : "icon";
    return (
        <figure
            className={css + ` ${className}`}
            onClick={(e) => onclick(content, e)}
        >
            <img src={logo} alt={title} />
            <figcaption className="icon-selection__title has-text-centered">
                <span className="icon-selection__label">{title}</span>
            </figcaption>
        </figure>
    );
};

export default PriceButton;
