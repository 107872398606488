import React from 'react';
import PropTypes from "prop-types";
import PriceButton from './PriceButton';
import AbstractPriceWidget from './AbstractPriceWidget';
import QueryString from '../../helper/QueryString';

export class PriceWidgetFinance extends AbstractPriceWidget {

    constructor(props) {
        super(props);
        this.progress = 95;
        this.cssClass = 'finance';
        this.question = 'Welches Finanzierungsmodell kommt für Sie in Frage?';
        let special = (new QueryString()).getSpecial();
        this.nextButtonStep = special === 'partner' ? 'postalCode' : 'checkout';
        if (this.prices.finance != null) {
            this.updated([
                { key: 'nextButtonStep', value: this.nextButtonStep },
                { key: 'showNextButton', value: true }
            ]);
        }
        
    }
    elementButtons() {
        let { finance } = this.props.prices;
        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column"><PriceButton
                    title="Leasing"
                    logo='/img/price_calc/109 Mieten.svg'
                    content="yes"
                    active={finance === 'Leasing'}
                    onclick={() => { this.update('Leasing'); }} /></div>
                <div className="column"><PriceButton
                    title="Kauf"
                    logo='/img/price_calc/109 Kaufen.svg'
                    content="Kauf"
                    active={finance === 'Kauf'}
                    onclick={() => { this.update('Kauf'); }} /></div>
                <div className="column"><PriceButton
                    title="Beides interessant"
                    logo='/img/price_calc/000 Weiss nicht.svg'
                    content="Beides interessant"
                    active={finance === 'Leasing + Kauf interessant'}
                    onclick={() => { this.update('Leasing + Kauf interessant'); }} /></div>
            </div>
        );
    }

    update(finance) {
            this.updated([
                { key: 'finance', value: finance },
                { key: 'contentWidgetStep', value: this.nextButtonStep }
            ]);
    }
}

PriceWidgetFinance.propTypes = {
    updated: PropTypes.func.isRequired,
}

export default PriceWidgetFinance;
