import React from "react";
import PriceButton from "../PriceButton";
import PropTypes from "prop-types";
import AbstractPriceWidget from "../AbstractPriceWidget";
import GoogleAnalytics from "../../../helper/GoogleAnalytics";

class PriceWidgetVideoCCTVType extends AbstractPriceWidget {
    constructor(props) {
        super(props);
        this.progress = 45;
        this.cssClass = "cctv-type";
        this.question = "Welche Kameraform würden Sie bevorzugen?";
        this.setEventName("DomeBullet");
        if (this.prices.cctv_type != null) {
            this.updated([
                { key: "nextButtonStep", value: "videoCctvResolution" },
                { key: "showNextButton", value: true },
            ]);
        }
    }
    componentDidMount() {
        super.componentDidMount(this);
        let ga = new GoogleAnalytics();
        ga.sendGTMEvent("PR_V_Q3_Amount_Answered");
    }
    elementButtons() {
        let { cctv_type } = this.prices;
        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column">
                    <PriceButton
                        title="Bulletkameras"
                        logo="/img/price_calc/203 Bullet Kamera.svg"
                        content="Bulletkameras"
                        active={cctv_type === "Bulletkameras"}
                        onclick={() => {
                            this.update("Bulletkameras");
                        }}
                        className="PR_V_Q4_Form_Answered"
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Domekameras"
                        logo="/img/price_calc/203 Dome Kamera.svg"
                        content="Domekameras"
                        active={cctv_type === "Domekameras"}
                        onclick={() => {
                            this.update("Domekameras");
                        }}
                        className="PR_V_Q4_Form_Answered"
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Bullet- & Domekameras"
                        logo="/img/price_calc/203 Bullet und Dome Kamera.svg"
                        content="Bullet- & Domekameras"
                        active={cctv_type === "Bullet- & Domekameras"}
                        onclick={() => {
                            this.update("Bullet- & Domekameras");
                        }}
                        className="PR_V_Q4_Form_Answered"
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Weiß nicht"
                        logo="/img/price_calc/000 Weiss nicht.svg"
                        content="Weiß nicht"
                        active={cctv_type === "Weiß nicht"}
                        onclick={() => {
                            this.update("Weiß nicht");
                        }}
                        className="PR_V_Q4_Form_Answered"
                    />
                </div>
            </div>
        );
    }
    update(cctv_type) {
        this.updated([
            { key: "cctv_type", value: cctv_type },
            { key: "contentWidgetStep", value: "videoCctvResolution" },
        ]);
    }
}

PriceWidgetVideoCCTVType.propTypes = {
    updated: PropTypes.func.isRequired,
};

export default PriceWidgetVideoCCTVType;
