import React from 'react';
import PropTypes from "prop-types";
import PriceButton from './PriceButton';
import moment from 'moment';
import 'moment/locale/de';
import AbstractPriceWidget from './AbstractPriceWidget';
import QueryString from '../../helper/QueryString';

export class PriceWidgetKfW extends AbstractPriceWidget {

    constructor(props) {
        super(props);
        this.progress = 95;
        this.cssClass = 'kfw';
        moment.locale('de');
        let currentDate = moment();
        currentDate.date(21);
        if (moment().date() >= 28) {
            currentDate = currentDate.add(2, 'month');
        } else {
            currentDate = currentDate.add(1, 'month');
        }
        this.questionHtml = '<h3>Haben Sie Interesse an staatlicher Förderung?</h3><p>Sichern Sie sich noch bis zum <strong>' + currentDate.format('Do MMMM') + '</strong> bis zu <strong>20% Rabatt</strong> von der KfW</h3>'
        let special = (new QueryString()).getSpecial();
        this.nextButtonStep = 'checkout';

        this.setEventName('KfW');

        // this.nextButtonStep = special === 'partner' ? 'postalCode' : 'checkout';
        // Start Isabeau #2
        // let originalLandingPage = (new QueryString()).getQuery().originalLandingPage;
        // let directVisitor = originalLandingPage === 'http://localhost/'
        //     || originalLandingPage === 'https://www.ma24-staging.fucking-awesome.me/'
        //     || originalLandingPage === 'https://www.meinalarm24.de/';
        // this.nextButtonStep = (special === 'partner' || directVisitor) ? 'postalCode' : 'checkout';
        // End Isabeau #2
        this.updated([{ key: 'showSkip', value: false }]);
        if (this.prices.cctv_disk != null) {
            this.updated([
                { key: 'nextButtonStep', value: this.nextButtonStep },
                { key: 'showNextButton', value: true },
                { key: 'showSkip', value: false }
            ]);
        }
    }

    elementButtons() {
        let { kfw } = this.props.prices;
        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column"><PriceButton
                    title="Ja"
                    logo='/img/price_calc/109 KfW Foerderung.svg'
                    content="yes"
                    active={kfw === 'Ja'}
                    onclick={() => { this.update('Ja'); }} /></div>
                <div className="column"><PriceButton
                    title="Nein"
                    logo='/img/price_calc/000 Nein.svg'
                    content="no"
                    active={kfw === 'Nein'}
                    onclick={() => { this.update('Nein'); }} /></div>
                <div className="column"><PriceButton
                    title="Weiß nicht"
                    logo='/img/price_calc/000 Weiss nicht.svg'
                    content="dont_know"
                    active={kfw === 'Weiß ich nicht'}
                    onclick={() => { this.update('Weiß ich nicht'); }} /></div>
            </div>
        );
    }

    update(kfw) {
        this.updated([
            { key: 'kfw', value: kfw },
            { key: 'contentWidgetStep', value: this.nextButtonStep }
        ]);
    }
}

PriceWidgetKfW.propTypes = {
    updated: PropTypes.func.isRequired,
}

export default PriceWidgetKfW;
