import React from "react";
import PropTypes from "prop-types";
import GoogleAnalytics from "../../helper/GoogleAnalytics";
import {
    Brand,
    Tuerschloss,
    Alarmanlage,
    Videoueberwachung,
    BeidesInteressant,
    WeissNicht,
} from "./elements/Constants";
import Fade from "react-reveal/Fade";

export class PriceWidgetThanks extends React.Component {
    constructor(props) {
        super(props);
        this.updated = this.props.updated;
        this.updated([
            { key: "showBottomButtons", value: false },
            { key: "showNextButton", value: false },
        ]);

        this.bookingLink =
            "https://outlook.office365.com/owa/calendar/beratungstermine@meinalarm24.de/bookings/s/";
        switch (props.prices.region) {
            case "Berlin":
                this.bookingLink = this.bookingLink + "CJqj_1X8xkKR6d6JyblBVQ2";
                break;

            case "Hamburg":
                this.bookingLink = this.bookingLink + "9mkN--5-qkqTCtE_d5hn0g2";
                break;

            case "Cologne":
            case "Duesseldorf":
            case "Essen":
            case "Bochum":
            case "Duisburg":
            case "Krefeld":
            case "Moenchengladbach":
                this.bookingLink = this.bookingLink + "EFMAD37xG0ii35NrrqmGGQ2";
                break;

            case "Oldenburg":
            case "Munich":
            case "Bayern":
            case "Niedersachsen":
            case "Bremen":
                this.bookingLink = this.bookingLink + "71nPkBoJAU2WikYdIXL68Q2";
                break;

            default:
                this.bookingLink = "/beratungstermine/";
                break;
        }
    }

    componentDidMount() {
        let ga = new GoogleAnalytics();
        ga.sendEvent("preisrechner", "reached", "thank you");
        this.sendEvent();
    }

    sendEvent() {
        let ga = new GoogleAnalytics();
        var prefix = "unknown";
        if (this.props.prices.securitySystem === Brand) {
            prefix = "f";
        } else if (this.props.prices.securitySystem === Tuerschloss) {
            prefix = "d";
        } else if (this.props.prices.securitySystem === Alarmanlage) {
            prefix = "a";
        } else if (this.props.prices.securitySystem === Videoueberwachung) {
            prefix = "v";
        } else if (
            this.props.prices.securitySystem === BeidesInteressant ||
            this.props.prices.securitySystem === WeissNicht
        ) {
            prefix = "av";
        }
        ga.sendGTMEvent("calculator/" + prefix + "/Thankyou");
    }

    render() {
        return (
            <div className="thanks" key="thanks" style={{ marginTop: "10px" }}>
                <Fade right>
                    <div className="columns thanks-top is-mobile">
                        <div className="column is-narrow thanks-top-left has-text-centered">
                            <i className="fas fa-check"></i>
                        </div>
                        <div className="column is-vertical-center thanks-top-middle">
                            <span className="is-size-4-tablet">
                                Herzlichen Dank für Ihre Preisanfrage!
                            </span>
                            <div className="arrow-down"></div>
                        </div>
                    </div>
                    <div className="content has-text-centered">
                        <div className="columns">
                            <div className="column">
                                <figure className="image customer-agent">
                                    <img
                                        src="/img/image_2_kostenlose_beratung.svg"
                                        alt="Kostenlose Beratung"
                                    />
                                </figure>
                            </div>
                        </div>
                        <p className="is-size-5">
                            <strong>Wie geht es weiter?</strong>
                        </p>
                        <p className="is-size-5">
                            Ein MeinAlarm24 Sicherheitsberater berechnet aktuell
                            einen ersten Preis für Ihr Projekt und wird sich bei
                            offen gebliebenen Fragen unter der folgenden
                            Telefonnummer bei Ihnen melden:
                        </p>
                        <p className="is-size-5">
                            <strong>{this.props.prices.phone}</strong>
                        </p>
                        {/* <p className="is-size-5">Sind Sie unter einer anderen Nummer oder zu einem anderen Zeitpunkt besser erreichbar?</p>
                        <div className="cta-btn">
                            <a
                                className="button preisrechner-btn preisrechner-btn-primary"
                                href={this.bookingLink}>
                                Wunschtermin buchen
                            </a>
                            <p className=''>100% kostenlos</p>
                        </div> */}
                    </div>
                </Fade>
            </div>
        );
    }
}

PriceWidgetThanks.propTypes = {
    updated: PropTypes.func.isRequired,
};

export default PriceWidgetThanks;
