import React from 'react';
import PriceButton from './PriceButton';
import PropTypes from "prop-types";
import AbstractPriceWidget from './AbstractPriceWidget';
import ReactHtmlParser from 'react-html-parser';

class PriceWidgetCCTVResolution extends AbstractPriceWidget {
    constructor(props) {
        super(props);
        this.progress = '75';
        this.cssClass = 'cctv-resolution';
        this.question = 'Wie hoch sollte die Auflösung der Kameras sein?';
        this.setEventName('CameraResolution');
        if (this.prices.cctv_resolution != null) {
            this.updated([
                { key: 'nextButtonStep', value: 'smartphone' },
                { key: 'showNextButton', value: true }
            ]);
        }
    }

    elementButtons() {
        let {cctv_resolution} = this.prices;
        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column"><PriceButton
                    title={ReactHtmlParser('2 Megapixel<br/>(HD Qualität)')}
                    logo='/img/price_calc/207 HD.svg'
                    content="2 Megapixel (HD Qualität)"
                    active={cctv_resolution === '2 Megapixel (HD Qualität)'}
                    onclick={() => { this.update('2 Megapixel (HD Qualität)'); }} /></div>
                <div className="column"><PriceButton
                    title="4 Megapixel"
                    logo='/img/price_calc/207 4MP.svg'
                    content="4 Megapixel"
                    active={cctv_resolution === '4 Megapixel'}
                    onclick={() => { this.update('4 Megapixel'); }} /></div>
                <div className="column"><PriceButton
                    title={ReactHtmlParser('8 Megapixel<br/>(4K UHD Qualität)')}
                    logo='/img/price_calc/207 4K UHD.svg'
                    content="8 Megapixel (4K UHD Qualität)"
                    active={cctv_resolution === '8 Megapixel (4K UHD Qualität)'}
                    onclick={() => { this.update('8 Megapixel (4K UHD Qualität)'); }} /></div>
                <div className="column"><PriceButton
                    title="Weiß nicht"
                    logo='/img/price_calc/000 Weiss nicht.svg'
                    content="Weiß nicht"
                    active={cctv_resolution === 'Weiß nicht'}
                    onclick={() => { this.update('Weiß nicht'); }} /></div>
            </div>
        )
    }
    update (cctv_resolution) {
        this.updated([
            { key: 'cctv_resolution', value: cctv_resolution },
            { key: 'contentWidgetStep', value: 'smartphone' }
        ]);
    }
}

PriceWidgetCCTVResolution.propTypes = {
    updated: PropTypes.func.isRequired,
}

export default PriceWidgetCCTVResolution;
