import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import AbstractPriceWidget from "../AbstractPriceWidget";
import GoogleAnalytics from "../../../helper/GoogleAnalytics";

export class PriceWidgetFireAlarmFloors extends AbstractPriceWidget {
    constructor(props) {
        super(props);
        this.progress = 60;
        this.eventName = "calculator/f/Floors";
        this.question = "Wie viele Etagen hat die abzusichernde Immobilie?";
        this.cssClass = "windows";
        this.nextStep = "fireAlarmRealEstateSize";
        let data = [
            { key: "showNextButton", value: true },
            { key: "nextButtonStep", value: this.nextStep },
        ];
        this.updated(data);
    }

    componentDidMount() {
        super.componentDidMount(this);
        let ga = new GoogleAnalytics();
        ga.sendGTMEvent("PR_F_Q2_ExistingSystem_Answered");
    }
    elementButtons() {
        var { fireAlarmFloors } = this.prices;
        let elements = [];
        for (var i = 1; i <= 25; i++) {
            elements.push(
                <option key={i} value={i}>
                    {i}
                </option>
            );
        }
        return (
            <div className="columns is-gapless-desktop">
                <div className="column price-range-text">
                    <strong>Etagenanzahl :</strong>
                </div>
                <div className="column price-range">
                    <div className="select">
                        <select
                            onChange={(event) => {
                                this.updated([
                                    {
                                        key: "fireAlarmFloors",
                                        value: event.target.value,
                                    },
                                ]);
                            }}
                            defaultValue={fireAlarmFloors}
                        >
                            {elements}
                        </select>
                    </div>
                </div>
                <div className="column price-range PR_F_Q3_Floors_Answered">
                    <button
                        className="button"
                        onClick={() => {
                            this.updated([
                                { key: "fireAlarmFloors", value: "Weiß nicht" },
                                {
                                    key: "contentWidgetStep",
                                    value: this.nextStep,
                                },
                            ]);
                        }}
                    >
                        Weiß nicht
                    </button>
                </div>
            </div>
        );
    }
}

PriceWidgetFireAlarmFloors.propTypes = {
    updated: PropTypes.func.isRequired,
};

export default PriceWidgetFireAlarmFloors;
