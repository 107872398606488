import React from "react";

const PriceVorteile = ({ name, mainStyle, subStyle }) => {
    return (
        <div>
            {/* <div className={"columns " + name + " " + mainStyle}>
                <div className={"column " + name + "-left " + subStyle}>
                    <i className="fas fa-check"></i>{" "}
                    <span style={{ fontSize: "0.9rem" }}>
                        Kostenlose Beratung
                    </span>
                </div>
                <div className={"column " + name + "-middle " + subStyle}>
                    <i className="fas fa-check"></i>{" "}
                    <span style={{ fontSize: "0.9rem" }}>
                        99,2% Kundenzufriedenheit
                    </span>
                </div>
                <div className={"column " + name + "-right " + subStyle}>
                    <i className="fas fa-check"></i>{" "}
                    <span style={{ fontSize: "0.9rem" }}>
                        Zertifizierte Techniker
                    </span>
                </div>
            </div> */}

            <p className="only-mobile">
                <h3 style={{ fontSize: "16px", marginBottom: "10px" }}>
                    Ausgezeichnet durch:
                </h3>
                <img
                    src="/img/homepage/Google-Reviews-Picture  768 and larger.png"
                    style={{ height: "48px" }}
                />
                <img
                    src="/img/homepage/Meisterbetrieb-Picture 768 and larger.png"
                    style={{
                        marginLeft: "12px",
                        height: "48px",
                    }}
                />
                <img
                    src="/img/homepage/Ausgezeichnet.org-Reviews-Picture  768 and larger.png"
                    style={{
                        marginLeft: "12px",
                        height: "48px",
                    }}
                />
            </p>

            <p className="only-desktop" style={{ marginTop: "20px" }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <h3
                        style={{
                            fontSize: "16px",
                            marginBottom: "10px",
                            textAlign: "center",
                        }}
                    >
                        Ausgezeichnet <br />
                        durch:
                    </h3>
                    <img
                        src="/img/homepage/Google-Reviews-Picture  768 and larger.png"
                        style={{
                            marginLeft: "10px",
                            height: "65px",
                        }}
                    />
                    <img
                        src="/img/homepage/Meisterbetrieb-Picture 768 and larger.png"
                        style={{
                            marginLeft: "20px",
                            height: "65px",
                        }}
                    />
                    <img
                        src="/img/homepage/Ausgezeichnet.org-Reviews-Picture  768 and larger.png"
                        style={{
                            marginLeft: "20px",
                            height: "65px",
                        }}
                    />
                </div>
            </p>
        </div>
    );
};

export default PriceVorteile;
