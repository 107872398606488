import React from "react";
import PriceButton from "../PriceButton";
import PropTypes from "prop-types";
import GoogleAnalytics from "../../../helper/GoogleAnalytics";
import AbstractPriceWidget from "../AbstractPriceWidget";
import { Privat, Behoerde, Andere, Gewerbe } from "../elements/Constants";

class PriceStartWidgetFireAlarm extends AbstractPriceWidget {
    constructor(props) {
        super(props);

        if (this.prices.objectTypeWidget != null) {
            this.updated([
                { key: "nextButtonStep", value: "fireAlarmAlreadyEquiped" },
                { key: "showNextButton", value: true },
            ]);
        }
        this.eventName = "calculator/f/PropertyType";
        this.progress = 20;
        this.nextStep = "fireAlarmAlreadyEquiped";
        this.cssClass = "main";
        this.question =
            "Für welche Immobilie suchen Sie eine Brandmeldeanlage?";
    }

    componentDidMount() {
        super.componentDidMount(this);
        let ga = new GoogleAnalytics();
        ga.sendEvent("preisrechnerFireAlarm", "reached", "question 1");
        ga.sendEvent("preisrechner", "reached", "question 1");
    }

    elementButtons() {
        let { objectType } = this.props.prices;
        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column">
                    <PriceButton
                        title="Gewerbe"
                        logo="/img/price_calc/011 Gewerbe.svg"
                        content="Gewerbe"
                        active={objectType === Gewerbe}
                        onclick={() => {
                            this.update("Gewerbe", Gewerbe);
                        }}
                        className="PR_F_Q1_Property_Answered"
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Behörde"
                        logo="/img/price_calc/012 Behörde.svg"
                        content="Behörde"
                        active={objectType === Behoerde}
                        onclick={() => {
                            this.update("Behörde", Behoerde);
                        }}
                        className="PR_F_Q1_Property_Answered"
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Privat"
                        logo="/img/price_calc/013 Privat.svg"
                        content="privat"
                        active={objectType === Privat}
                        onclick={() => {
                            this.update("privat", Privat);
                        }}
                        className="PR_F_Q1_Property_Answered"
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Andere Immobilie"
                        logo="/img/price_calc/014 Andere Immobilie.svg"
                        content="other"
                        active={objectType === Andere}
                        onclick={() => {
                            this.update("other", Andere);
                        }}
                        className="PR_F_Q1_Property_Answered"
                    />
                </div>
            </div>
        );
    }

    update(objectTypeWidget, objectType) {
        this.updated([
            { key: "coronaBanner", value: false },
            { key: "objectType", value: objectType },
            { key: "objectTypeWidget", value: objectTypeWidget },
            { key: "contentWidgetStep", value: this.nextStep },
        ]);
    }
}

PriceStartWidgetFireAlarm.propTypes = {
    updated: PropTypes.func.isRequired,
};

export default PriceStartWidgetFireAlarm;
