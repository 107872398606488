import React from "react";
import PropTypes from "prop-types";
import PriceButton from "./PriceButton";
import AbstractPriceWidget from "./AbstractPriceWidget";
import QueryString from "../../helper/QueryString";

export class PriceWidgetSmartphone extends AbstractPriceWidget {
    constructor(props) {
        super(props);
        let { smartphone } = this.prices;
        this.progress = 90;
        this.cssClass = "smartphone";
        this.setEventName("Smartphone");
        if (this.isSecuritySystemVideo()) {
            this.question =
                "Möchten Sie gerne per Smartphone App auf Live-Aufnahmen zugreifen können?";
        } else if (this.isSecuritySystemAlarm()) {
            this.question =
                "Möchten Sie gerne per Smartphone App auf Ihre Alarmanlage zugreifen können?";
        } else {
            this.question =
                "Möchten Sie gerne per Smartphone App auf Live-Aufnahmen und Ihre Alarmanlage zugreifen können?";
        }
        // this.nextStep = 'kfw';
        this.nextStep = "checkout";
        // Start Isabeau #2
        // let special = (new QueryString()).getSpecial();
        // let originalLandingPage = (new QueryString()).getQuery().originalLandingPage;
        // let directVisitor = originalLandingPage === 'http://localhost/'
        //     || originalLandingPage === 'https://www.ma24-staging.fucking-awesome.me/'
        //     || originalLandingPage === 'https://www.meinalarm24.de/';
        // this.nextStep = (special === 'partner' || directVisitor) ? 'budget' : 'kfw';
        // End Isabeau #2
        // if (this.isObjectTypeBusiness() && this.nextStep === 'kfw') {
        if (this.isObjectTypeBusiness()) {
            this.nextStep = "checkout";
        }
        if (smartphone !== null) {
            this.updated([
                { key: "nextButtonStep", value: this.nextStep },
                { key: "showNextButton", value: true },
            ]);
        }
    }

    elementButtons() {
        let { smartphone } = this.prices;
        let yesImage = "/img/price_calc/108 Smartphone Alarm.svg";
        if (this.isSecuritySystemVideo()) {
            yesImage = "/img/price_calc/208 Smartphone Video.svg";
        }
        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column">
                    <PriceButton
                        title="Ja"
                        logo={yesImage}
                        content="yes"
                        active={smartphone === "Ja"}
                        onclick={() => {
                            this.update("Ja");
                        }}
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Nein"
                        logo="/img/price_calc/000 Nein.svg"
                        content="no"
                        active={smartphone === "Nein"}
                        onclick={() => {
                            this.update("Nein");
                        }}
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Weiß nicht"
                        logo="/img/price_calc/000 Weiss nicht.svg"
                        content="dont_know"
                        active={smartphone === "Weiß ich nicht"}
                        onclick={() => {
                            this.update("Weiß ich nicht");
                        }}
                    />
                </div>
            </div>
        );
    }

    update(smartphone) {
        this.updated([
            { key: "smartphone", value: smartphone },
            { key: "contentWidgetStep", value: this.nextStep },
        ]);
    }
}

PriceWidgetSmartphone.propTypes = {
    updated: PropTypes.func.isRequired,
};

export default PriceWidgetSmartphone;
