import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import AbstractPriceWidget from "../AbstractPriceWidget";
import { Gewerbe, Buero } from "../elements/Constants";
import GoogleAnalytics from "../../../helper/GoogleAnalytics";

export class PriceWidgetDoorLockCount extends AbstractPriceWidget {
    constructor(props) {
        super(props);
        this.progress = 40;
        this.cssClass = "doorLockCount";
        this.question =
            "Wie viele Türen möchten Sie mit elektronischen Türschlössern absichern?";
        this.eventName = "calculator/d/AmountOfDoors";
        if (this.prices.doorLockCount !== null) {
            let data = [
                { key: "showNextButton", value: true },
                { key: "nextButtonStep", value: "doorLockUserCount" },
                { key: "doorLockCount", value: 1 },
            ];
            this.updated(data);
        }
    }
    componentDidMount() {
        super.componentDidMount(this);
        let ga = new GoogleAnalytics();
        ga.sendGTMEvent("PR_D_Q1_Property_Answered");
    }
    elementButtons() {
        var { doorLockCount, objectType } = this.prices;
        let elements = [];
        const isBusiness = objectType === Gewerbe || objectType === Buero;
        let maxDoors = isBusiness ? 100 : 10;
        for (var i = 1; i <= maxDoors; i++) {
            elements.push(
                <option key={i} value={i}>
                    {i}
                </option>
            );
        }
        if (isBusiness) {
            elements.push(
                <option key={102} value={">100"}>
                    {">100"}
                </option>
            );
        } else {
            elements.push(
                <option key={12} value={">10"}>
                    {">10"}
                </option>
            );
        }
        return (
            <div className="columns is-gapless-desktop">
                <div className="column price-range-text">
                    <strong>Anzahl Türen: </strong>
                </div>
                <div className="column price-range PR_D_Q2_Amount_Answered">
                    <div className="select">
                        <select
                            onChange={(event) => {
                                this.updated([
                                    {
                                        key: "doorLockCount",
                                        value: event.target.value,
                                    },
                                ]);
                            }}
                            defaultValue={doorLockCount}
                        >
                            {elements}
                        </select>
                    </div>
                </div>
                <div
                    className="column price-range PR_D_Q2_Amount_Answered
"
                >
                    <button
                        className="button"
                        onClick={() => {
                            this.updated([
                                { key: "doorLockCount", value: "Weiß nicht" },
                                {
                                    key: "contentWidgetStep",
                                    value: "doorLockUserCount",
                                },
                            ]);
                        }}
                    >
                        Weiß nicht
                    </button>
                </div>
            </div>
        );
    }
    update(doorLockCount) {
        let data = [{ key: "doorLockCount", value: doorLockCount }];
        this.updated(data);
    }
}

PriceWidgetDoorLockCount.propTypes = {
    updated: PropTypes.func.isRequired,
};

export default PriceWidgetDoorLockCount;
