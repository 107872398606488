import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import PriceButton from "../PriceButton";
import AbstractPriceWidget from "../AbstractPriceWidget";
import GoogleAnalytics from "../../../helper/GoogleAnalytics";

export class PriceWidgetAlarmPets extends AbstractPriceWidget {
    constructor(props) {
        super(props);
        this.progress = 75;
        this.question =
            "Haben Sie Haustiere, die sich frei im Innenraum bewegen können?";
        this.cssClass = "pets";
        this.setEventName("Pets");
        if (this.prices.pets !== null) {
            let data = [
                { key: "showNextButton", value: true },
                { key: "nextButtonStep", value: "alarmPanicButton" },
            ];
            this.updated(data);
        }
    }

    componentDidMount() {
        super.componentDidMount(this);
        let ga = new GoogleAnalytics();
        // ga.sendGTMEvent("PR_I_Q3_Doors_Answered");
    }
    elementButtons() {
        let { pets } = this.prices;
        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column">
                    <PriceButton
                        title="Ja"
                        logo="/img/price_calc/105 Tiere.svg"
                        content="yes"
                        active={pets === "Ja"}
                        onclick={() => {
                            this.update("Ja");
                        }}
                    />
                </div>
                <div className="column">
                    <PriceButton
                        title="Nein"
                        logo="/img/price_calc/000 Nein.svg"
                        content="no"
                        active={pets === "Nein"}
                        onclick={() => {
                            this.update("Nein");
                        }}
                    />
                </div>
            </div>
        );
    }

    update(pets) {
        let data = [
            { key: "pets", value: pets },
            { key: "contentWidgetStep", value: "alarmPanicButton" },
        ];
        this.updated(data);
    }
}

PriceWidgetAlarmPets.propTypes = {
    updated: PropTypes.func.isRequired,
};

export default PriceWidgetAlarmPets;
