import React, { useState } from "react";
import {
    Alarmanlage,
    Videoueberwachung,
    Tuerschloss,
    Brand,
} from "./elements/Constants";

function PriceCalcNavigation({ product, city }) {
    let priceCalcName;
    let priceCalcLinks;
    let urlPrefix = city ? "/" + city.slug : "";
    const [showComp, setShowComp] = useState(false);
    switch (product) {
        case Alarmanlage:
            priceCalcName = "Alarmanlage";
            priceCalcLinks = `<a href="${urlPrefix}/videoueberwachung/preisrechner" id="btn_bighero_preisberechnen">Videoanlagen</a> | <a href="${urlPrefix}/schliessanlagen/preisrechner">Schließanlagen</a> | <a href="/brandmeldeanlagen/preisrechner">Brandmeldeanlagen</a>`;
            break;

        case Videoueberwachung:
            priceCalcName = "Videoanlage";
            priceCalcLinks = `<a href="${urlPrefix}/preisrechner" >Alarmanlagen</a> | <a href="${urlPrefix}/schliessanlagen/preisrechner">Schließanlagen</a> | <a href="/brandmeldeanlagen/preisrechner">Brandmeldeanlagen</a>`;
            break;

        case Tuerschloss:
            priceCalcName = "Schließanlage";
            priceCalcLinks = `<a href="${urlPrefix}/preisrechner">Alarmanlagen</a> | <a href="${urlPrefix}/videoueberwachung/preisrechner">Videoanlagen</a> | <a href="/brandmeldeanlagen/preisrechner">Brandmeldeanlagen</a>`;
            break;

        case Brand:
            priceCalcName = "Brandmeldeanlage";
            priceCalcLinks = `<a href="${urlPrefix}/preisrechner">Alarmanlagen</a> | <a href="${urlPrefix}/schliessanlagen/preisrechner">Videoanlagen</a> | <a href="${urlPrefix}/schliessanlagen/preisrechner">Schließanlagen</a>`;
            break;

        default:
            priceCalcName = "Alarmanlage";
            priceCalcLinks = `<a href="${urlPrefix}/videoueberwachung/preisrechner">Videoanlagen</a> | <a href="/schliessanlagen/preisrechner">Schließanlagen</a> | <a href="/brandmeldeanlagen/preisrechner">Brandmeldeanlagen</a>`;
            break;
    }
    let showCompData = false;
    if (typeof window !== "undefined" && typeof document !== "undefined") {
        let path = window.location.pathname;
        path = path.replace(/\//g, "");
        console.log("load script firedddddddddddddddddddddddddddd", path);
        const removeArray = [
            "schliessanlagenpreisrechner",
            "videoueberwachungpreisrechner",
            "alarmanlagenpreisrechner",
            "brandmeldeanlagenpreisrechner",
            "preisrechner",
        ];
        if (removeArray.includes(path)) {
            // setShowComp(true);
            showCompData = true;
        } else {
            // setShowComp(false);
            showCompData = false;
        }
    }
    return (
        <div>
            {showCompData ? (
                <div className="columns is-mobile price-calc-navigation">
                    <div className="column is-hidden-mobile" />
                    <div className="column message-box">
                        <div className="content-box">
                            <div className="message">
                                Sie berechnen aktuell den Preis für eine{" "}
                                <strong>{priceCalcName}</strong>.
                            </div>
                            <div className="message">
                                Sie wollen lieber den Preis für eine andere
                                Anlage berechnen? Hier geht es zu{" "}
                                <br className="is-hidden-tablet" />
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: priceCalcLinks,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="column is-hidden-mobile" />
                </div>
            ) : (
                ""
            )}
        </div>
    );
}

export default PriceCalcNavigation;
