import React from 'react';
import PriceButton from './PriceButton';
import PropTypes from "prop-types";
import AbstractPriceWidget from './AbstractPriceWidget';

class PriceWidgetCCTVType extends AbstractPriceWidget {
    constructor(props) {
        super(props);
        this.progress = 65;
        this.cssClass = 'cctv-type';
        this.question = 'Welche Kameraform würden Sie bevorzugen?';
        this.setEventName('DomeBullet');
        if (this.prices.cctv_type != null) {
            this.updated([
                { key: 'nextButtonStep', value: 'cctv_resolution' },
                { key: 'showNextButton', value: true }
            ]);
        }
    }

    elementButtons() {
        let {cctv_type} = this.prices;
        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column"><PriceButton
                    title="Bulletkameras"
                    logo='/img/price_calc/203 Bullet Kamera.svg'
                    content="Bulletkameras"
                    active={cctv_type === 'Bulletkameras'}
                    onclick={() => { this.update('Bulletkameras'); }} /></div>
                <div className="column"><PriceButton
                    title="Domekameras"
                    logo='/img/price_calc/203 Dome Kamera.svg'
                    content="Domekameras"
                    active={cctv_type === 'Domekameras'}
                    onclick={() => { this.update('Domekameras'); }} /></div>
                <div className="column"><PriceButton
                    title='Bullet- & Domekameras'
                    logo='/img/price_calc/203 Bullet und Dome Kamera.svg'
                    content='Bullet- & Domekameras'
                    active={cctv_type === 'Bullet- & Domekameras'}
                    onclick={() => { this.update('Bullet- & Domekameras'); }} /></div>
                <div className="column"><PriceButton
                    title="Weiß nicht"
                    logo='/img/price_calc/000 Weiss nicht.svg'
                    content="Weiß nicht"
                    active={cctv_type === 'Weiß nicht'}
                    onclick={() => { this.update('Weiß nicht'); }} /></div>
            </div>
        )
    }
    update (cctv_type) {
        this.updated([
            { key: 'cctv_type', value: cctv_type },
            { key: 'contentWidgetStep', value: 'cctv_resolution' }
        ]);
    }
}

PriceWidgetCCTVType.propTypes = {
    updated: PropTypes.func.isRequired,
}

export default PriceWidgetCCTVType;
