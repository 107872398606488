import React from 'react';
import _ from 'lodash';
import PropTypes from "prop-types";
import AbstractPriceWidget from './AbstractPriceWidget';

export class PriceWidgetCCTVCount extends AbstractPriceWidget {

    constructor(props) {
        super(props);
        this.progress = 55;
        this.cssClass = 'cctv_count';
        this.question = 'Wie viele Videokameras benötigen Sie?';
        this.setEventName('AmountOfCameras');
        if (this.prices.cctv_count !== null) {
            let data = [
                { key: 'showNextButton', value: true }
            ];
            if (this.isSecuritySystemBoth()) {
                data = _.concat(data, [{ key: 'nextButtonStep', value: 'smartphone' }]);
                this.progress = 85;
            } else {
                data = _.concat(data, [{ key: 'nextButtonStep', value: 'cctv_type' }]);
            }
            this.updated(data);
        }
    }
    elementButtons() {
        var { cctv_count } = this.prices;
        let elements = [];
        for(var i = 1; i <= 20; i++) {
            elements.push(<option key={i} value={i}>{i}</option>)
        }
        return (
            <div className="columns is-gapless-desktop">
                <div className="column price-range-text">
                    <strong>Videokameras: </strong>
                </div>
                <div className="column price-range">
                    <div className="select">
                        <select onChange={(event) => { this.updated([{ key: 'cctv_count', value: event.target.value }]);}} defaultValue={cctv_count}>
                            {elements}
                        </select>
                    </div>
                </div>
                <div className="column price-range">
                    <button
                            className="button"
                            onClick={() => {
                                this.update('Weiß nicht');
                                }
                            }
                    >Weiß nicht</button>
                </div>
            </div>
        );
    }

    update (cctv_count) {
        let data = [
            { key: 'cctv_count', value: cctv_count }
        ];
        if (this.isSecuritySystemBoth()) {
            data = _.concat(data, [{ key: 'contentWidgetStep', value: 'smartphone' }]);
        } else {
            data = _.concat(data, [{ key: 'contentWidgetStep', value: 'cctv_type' }]);
        }
        this.updated(data);
    }
}

PriceWidgetCCTVCount.propTypes = {
    updated: PropTypes.func.isRequired,
}

export default PriceWidgetCCTVCount;
