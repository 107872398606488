import React from 'react';
import PropTypes from "prop-types";
import PriceButton from './PriceButton';
import AbstractPriceWidget from './AbstractPriceWidget';

export class PriceWidgetWindowsSaver extends AbstractPriceWidget {
    constructor(props) {
        super(props);
        let { windows_saver } = this.prices;
        this.progress = 65;
        this.cssClass = 'windows_saver';
        this.question = 'Hätten Sie gerne Glasbruchmelder zur Absicherung Ihrer Fensterscheiben?';
        this.setEventName('GlassBreakageDetector');
        this.nextStep = 'pets';
        if (this.isObjectTypeBusiness()) {
            if (this.isSecuritySystemBoth()) {
                this.nextStep = 'cctv';
            } else {
                this.nextStep = 'panic_button';
            }
        }
        if (windows_saver != null) {
            this.updated([
                { key: 'nextButtonStep', value: this.nextStep },
                { key: 'showNextButton', value: true }
            ]);
        }
    }

    elementButtons() {
        let { windows_saver } = this.prices;
        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column"><PriceButton
                    title="Ja"
                    logo='/img/price_calc/104 Glasbruchmelder.svg'
                    content="yes"
                    active={windows_saver === 'Ja'}
                    onclick={() => { this.update('Ja'); }} /></div>
                <div className="column"><PriceButton
                    title="Nein"
                    logo='/img/price_calc/000 Nein.svg'
                    content="no"
                    active={windows_saver === 'Nein'}
                    onclick={() => { this.update('Nein'); }} /></div>
                <div className="column"><PriceButton
                    title="Weiß nicht"
                    logo='/img/price_calc/000 Weiss nicht.svg'
                    content="dont_know"
                    active={windows_saver === 'Weiß ich nicht'}
                    onclick={() => { this.update('Weiß ich nicht'); }} /></div>
            </div>
        );
    }

    update(windows_saver) {
        this.updated([
            { key: 'windows_saver', value: windows_saver },
            { key: 'contentWidgetStep', value: this.nextStep }
        ]);
    }
}

PriceWidgetWindowsSaver.propTypes = {
    updated: PropTypes.func.isRequired,
}

export default PriceWidgetWindowsSaver;
