import React from "react";
import PropTypes from "prop-types";
import PriceButton from "../PriceButton";
import GoogleAnalytics from "../../../helper/GoogleAnalytics";
import AbstractPriceWidget from "../AbstractPriceWidget";
export class PriceWidgetDoorLockSmartphone extends AbstractPriceWidget {
    constructor(props) {
        super(props);
        let { smartphone } = this.prices;
        this.progress = 80;
        this.cssClass = "doorLockSmartphone";
        this.nextStep = "kfw";
        this.nextStep = "checkout";
        this.eventName = "calculator/d/Smartphone";
        this.question =
            "Möchten Sie die elektronischen Türschlösser auch per Smartphone App steuern können?";
        if (this.isObjectTypeBusiness() && this.nextStep === "kfw") {
            this.nextStep = "checkout";
        }
        if (smartphone !== null) {
            this.updated([
                { key: "nextButtonStep", value: this.nextStep },
                { key: "showNextButton", value: true },
            ]);
        }
    }
    componentDidMount() {
        super.componentDidMount(this);
        let ga = new GoogleAnalytics();
        ga.sendGTMEvent("PR_D_Q3_Users_Answered");
    }
    elementButtons() {
        let { doorLockSmartphone } = this.prices;
        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column  ">
                    <PriceButton
                        title="Ja"
                        logo="/img/price_calc/441 Smartphone Door Lock.svg"
                        content="yes"
                        active={doorLockSmartphone === "Ja"}
                        onclick={() => {
                            this.update("Ja");
                        }}
                        className="PR_D_Q1_Property_Answered"
                    />
                </div>
                <div className="column  ">
                    <PriceButton
                        title="Nein"
                        logo="/img/price_calc/442 Nein.svg"
                        content="no"
                        active={doorLockSmartphone === "Nein"}
                        onclick={() => {
                            this.update("Nein");
                        }}
                        className="PR_D_Q1_Property_Answered"
                    />
                </div>
                <div className="column  ">
                    <PriceButton
                        title="Weiß nicht"
                        logo="/img/price_calc/443 Weiss nicht.svg"
                        content="dont_know"
                        active={doorLockSmartphone === "Weiß ich nicht"}
                        onclick={() => {
                            this.update("Weiß ich nicht");
                        }}
                        className="PR_D_Q1_Property_Answered"
                    />
                </div>
            </div>
        );
    }

    update(doorLockSmartphone) {
        this.updated([
            { key: "doorLockSmartphone", value: doorLockSmartphone },
            { key: "contentWidgetStep", value: this.nextStep },
        ]);
    }
}

PriceWidgetDoorLockSmartphone.propTypes = {
    updated: PropTypes.func.isRequired,
};

export default PriceWidgetDoorLockSmartphone;
