import React from 'react';
import _ from 'lodash';
import PropTypes from "prop-types";
import AbstractPriceWidget from './AbstractPriceWidget';

export class PriceWidgetHouse extends AbstractPriceWidget {

    constructor(props) {
        super(props);
        this.progress = 55;
        this.question = 'Wie viele Fenster und Außentüren hat Ihre Immobilie?';
        this.cssClass = 'windows';
        this.setEventName('DoorsAndWindows');
        let data = [
            { key: 'showNextButton', value: true }
        ];
        if (this.isSecuritySystemBoth()) {
            data = _.concat(data, [{ key: 'nextButtonStep', value: 'pets' }]);
        } else {
            data = _.concat(data, [{ key: 'nextButtonStep', value: 'windows_saver' }]);
        }
        this.updated(data);
    }

    elementButtons() {
        var { windows } = this.prices;
        let elements = [];
        for(var i = 1; i <= 100; i++) {
            elements.push(<option key={i} value={i}>{i}</option>)
        }
        return (
            <div className="columns is-gapless-desktop">
                <div className="column price-range-text">
                    <strong>Anzahl Fenster und Außentüren: </strong>
                </div>
                <div className="column price-range">
                    <div className="select">
                        <select onChange={(event) => { this.updated([{ key: 'windows', value: event.target.value}]);}} defaultValue={windows}>
                            {elements}
                        </select>
                    </div>
                </div>
            </div>
        );
    }
}

PriceWidgetHouse.propTypes = {
    updated: PropTypes.func.isRequired,
}

export default PriceWidgetHouse;
