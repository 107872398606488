import React from 'react';
import PriceButton from './PriceButton';
import PropTypes from "prop-types";
import AbstractPriceWidget from './AbstractPriceWidget';
import { Alarmanlage, Videoueberwachung, BeidesInteressant, WeissNicht } from './elements/Constants';

class PriceWidgetSecuritySystem extends AbstractPriceWidget {
    constructor(props) {
        super(props);
        this.cssClass = 'securitySystem';
        this.question = 'Für welche Art der Absicherung interessieren Sie sich?';
        this.progress = 30;
        this.eventName = 'calculator/av/SecuritySystemCategory';
        this.updated([
            { key: 'nextButtonStep', value: null },
            { key: 'showNextButton', value: true }
        ]);
    }

    elementButtons() {
        let {securitySystem, objectTypeWidget} = this.prices;
        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column"><PriceButton
                    title="Alarmanlage"
                    logo='/img/price_calc/002 Alarmanlage.svg'
                    content="Alarmanlage"
                    active={securitySystem === Alarmanlage}
                    onclick={() => { this.update('floors', Alarmanlage); }} /></div>
                <div className="column"><PriceButton
                    title="Videoüberwachung"
                    logo='/img/price_calc/002 Videoanlage.svg'
                    content="Videoüberwachung"
                    active={securitySystem === Videoueberwachung}
                    onclick={() => { this.update('cctv', Videoueberwachung); }} /></div>
                <div className="column"><PriceButton
                    title="Beides interessant"
                    logo='/img/price_calc/002 Beides interessant.svg'
                    content="Beides interessant"
                    active={securitySystem === BeidesInteressant}
                    onclick={() => { this.update('floors', BeidesInteressant); }} /></div>
                <div className="column"><PriceButton
                    title="Weiß nicht"
                    logo='/img/price_calc/000 Weiss nicht.svg'
                    content="Weiß nicht"
                    active={securitySystem === WeissNicht}
                    onclick={() => { this.update('floors', WeissNicht); }} /></div>
            </div>
        )
    }
    update (nextStep, securitySystem) {
        this.updated([
            { key: 'securitySystem', value: securitySystem },
            { key: 'contentWidgetStep', value: nextStep }
        ]);
    }
}

PriceWidgetSecuritySystem.propTypes = {
    updated: PropTypes.func.isRequired,
}

export default PriceWidgetSecuritySystem;
