import React from 'react';

const PriceButtonCheckBox = ({ title, logo, onclick, content, active }) => {
    let css = active ? 'icon icon-active' : 'icon';
    const isMobile = typeof window != 'undefined' ? window.innerWidth < 480 : false;
    if (isMobile) {
        return (
            <div className={css + ' columns is-vcentered'} onClick={(e) => onclick(content, e)}>
                <img className="column no-margin is-narrow" src={logo} alt={title}/>
                <div className="column no-margin">
                    {title}
                </div>
                <div className="column no-margin checkbox-container-mobile">
                    <input type="checkbox" checked={active} className='checkbox-mobile no-margin' readOnly/>
                </div>
            </div>
        )
    } else {
        return (
            <figure className='column checkbox-container' className={css} onClick={(e) => onclick(content, e)}>
                <input type="checkbox" checked={active} className='column checkbox' readOnly/>
                <img src={logo} alt={title}/>
                <figcaption className="icon-selection__title has-text-centered">
                    <span className="icon-selection__label">{title}</span>
                </figcaption>
            </figure>
        );
    }
}

export default PriceButtonCheckBox;
