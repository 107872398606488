import React from 'react';
import _ from 'lodash';
import PriceButton from './PriceButton';
import PropTypes from "prop-types";
import AbstractPriceWidget from './AbstractPriceWidget';

class PriceWidgetCCTV extends AbstractPriceWidget {
    constructor(props) {
        super(props);
        this.progress = 45;
        this.question = 'Welche Bereiche möchten Sie videoüberwachen?';
        this.setEventName('IndoorOutdoor');
        this.cssClass = 'cctv';
        let { cctv } = this.prices;
        let data = [ { key: 'showNextButton', value: true } ];
        if (this.isSecuritySystemBoth()) {
            this.progress = 75;
        }
        if (cctv != null) {
            data = _.concat(data, [{ key: 'nextButtonStep', value: 'cctv_count' }]);
            data = _.concat(data, [{ key: 'showNextButton', value: true }]);
        }
        this.updated(data);
    }

    elementButtons() {
        let {cctv} = this.prices;
        return (
            <div className="columns icon-selection has-text-centered">
                <div className="column"><PriceButton
                    title="Innenbereich"
                    logo='/img/price_calc/205 Innenbereich.svg'
                    content="Innenbereich"
                    active={cctv === 'Innenbereich'}
                    onclick={() => { this.update('Innenbereich'); }} /></div>
                <div className="column"><PriceButton
                    title="Außenbereich"
                    logo='/img/price_calc/205 Aussenbereich.svg'
                    content="Außenbereich"
                    active={cctv === 'Außenbereich'}
                    onclick={() => { this.update('Außenbereich'); }} /></div>
                <div className="column"><PriceButton
                    title='Innen- & Außenbereich'
                    logo='/img/price_calc/205 Innen- und Aussenbereich.svg'
                    content='Innen- & Außenbereich'
                    active={cctv === 'Innen- & Außenbereich'}
                    onclick={() => { this.update('Innen- & Außenbereich'); }} /></div>
                <div className="column"><PriceButton
                    title="Weiß nicht"
                    logo='/img/price_calc/000 Weiss nicht.svg'
                    content="Weiß nicht"
                    active={cctv === 'Weiß nicht'}
                    onclick={() => { this.update('Weiß nicht'); }} /></div>
            </div>
        )
    }
    update (cctv) {
        let data = [ { key: 'cctv', value: cctv } ];
        data = _.concat(data, [{ key: 'contentWidgetStep', value: 'cctv_count' }]);
        this.updated(data);
    }
}

PriceWidgetCCTV.propTypes = {
    updated: PropTypes.func.isRequired,
}

export default PriceWidgetCCTV;
