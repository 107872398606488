import QueryString from './QueryString';
export default class LeadQualificator {
    constructor() {
        this.coveredPlz = [10,12,13,14,15,16,20,21,22,23,24,25,27,28,26,49,44,45,46,47,40,41,42,58,50,51,52,53,80,81,82,83,84,85,86];
        this.cities = {
            Berlin: [10,12,13,14,15,16],
            Hamburg: [20,21,22,23,24,25],
            Bremen: [27,28],
            Oldenburg: [26,49],
            Duesseldorf: [44,45,46,47,40,41,42,58],
            Cologne: [50,51,52,53],
            Munich: [80,81,82,83,84,85,86]
        };
        this.budgetLimit = 2000;
    }

    setLeadPotentiality(plz, budget, updated) {
        let plzCovered = this.plzIsCovered(plz);
        let budgetOk = this.budgetIsOk(budget);
        if (plzCovered) {
            (new QueryString()).setValue('region', this.getRegion(plz));
        }
        if (plzCovered && budgetOk) {
            updated([{key:'potential', value: 'High'}]);
        } else {
            updated([{key:'potential', value: 'Low'}]);
        }
    }

    budgetIsOk(budget) {
        if (budget > this.budgetLimit) {
            return true;
        }
        return false;
    }
    
    getRegion(plz) {
        let plzShort = parseInt(plz.substring(0, 2));
        let region = 'Unknown';
        Object.keys(this.cities).forEach(element => {
            if (this.cities[element].includes(plzShort)) {
                region = element;
            }
        });
        return region;
    }

    plzIsCovered(plz) {
        let plzShort = parseInt(plz.substring(0, 2));
        if (this.coveredPlz.includes(plzShort)) {
            return true;
        }
        return false;
    }
}