import React from 'react';
import PropTypes from "prop-types";
import PriceProgressBar from './elements/PriceProgressBar';
import { Alarmanlage, Videoueberwachung, BeidesInteressant, WeissNicht, Wohnung, Haus } from './elements/Constants';
import GoogleAnalytics from '../../helper/GoogleAnalytics';
import { Brand, Tuerschloss} from './elements/Constants';
import Fade from 'react-reveal/Fade';

class AbstractPriceWidget extends React.Component {
    constructor(props) {
        super(props);
        this.updated = this.props.updated;
        this.progress = 0;
        this.updated = this.props.updated;
        this.prices = this.props.prices;
        this.question = null;
        this.questionHtml = null;
        this.eventName = null;
        this.cssClass = 'main';
    }

    /**
     * return true if Alarmanlage System
     *
     * @return Boolean
     */
    isSecuritySystemAlarm() {
        return this.prices.securitySystem === Alarmanlage;
    }

    /**
     * return true if Videoueberwachung System
     *
     * @return Boolean
     */
    isSecuritySystemVideo() {
        return this.prices.securitySystem === Videoueberwachung;
    }

    /**
     * return true if Beides ineteressant or Weiß nicht
     *
     * @return Boolean
     */
    isSecuritySystemBoth() {
        return (this.prices.securitySystem === BeidesInteressant || this.prices.securitySystem === WeissNicht);
    }

    /**
     * return true if Wohnung or Haus
     *
     * @return Boolean
     */
    isObjectTypePrivate() {
        return (this.prices.objectType === Wohnung || this.prices.objectType === Haus);
    }

    /**
     * return if Büro or Gewerbe
     *
     * @return Boolean
     */
    isObjectTypeBusiness() {
        return !this.isObjectTypePrivate();
    }

    setEventName(name) {
        var prefix = 'unknown';
        if (this.isSecuritySystemAlarm()) {
            prefix = 'a';
        } else if (this.isSecuritySystemBoth()) {
            prefix = 'av';
        } else if (this.isSecuritySystemVideo()) {
            prefix = 'v';
        } else if (this.prices.securitySystem==Tuerschloss) {
            prefix = 'd';
        } else if (this.prices.securitySystem==Brand) {
            prefix = 'f';
        }
        this.eventName = 'calculator/'+prefix+'/'+name;
    }

    sendEvent() {
        if (this.eventName===null) {
            return
        }
        let ga = new GoogleAnalytics();
        ga.sendGTMEvent(this.eventName);
    }

    componentDidMount() {
        this.sendEvent()
    }

    render() {
        return (
            <div className="content" key={this.cssClass} >
                <PriceProgressBar value={this.progress} maximum='100' mainCssClass='only-desktop-flex'/>
                <PriceProgressBar value={this.progress} maximum='100' mainCssClass='only-mobile-flex'/>
                <Fade right>
                    <div className="columns price-header">
                        <div className="column">
                        {this.questionHtml &&
                            <div dangerouslySetInnerHTML={{__html: this.questionHtml}}></div>
                        }
                        {this.question &&
                            <h3>{this.question}</h3>
                        }
                        </div>
                    </div>
                </Fade>
                <Fade right>
                    {this.elementButtons()}
                </Fade>
            </div>
        )
    }

    /**
     * Abstract function for rendering button content
     */
    elementButtons() {
        return (
            <div></div>
        );
    }
}

AbstractPriceWidget.propTypes = {
    updated: PropTypes.func.isRequired,
    eventName: PropTypes.string.isRequired,
}

export default AbstractPriceWidget;
